@navBackgroundColor: #4c5166;
@navHoverColor: #8ac175;
@navHoverBackgroundColor: #fff;
@navDarkBackgroundColor: #0f1016;
@navDarkColor: #858a9f;

.skin-blue .main-sidebar {
    background-color: @navBackgroundColor;
}

.skin-blue .sidebar a {
    color: #fff;
}

a.custom-nav-item {
    display: block;
    padding: 12px 5px 12px 15px;
    cursor: pointer;
    border-left: 3px solid transparent;
    position: relative;
    > .fa {
        width: 20px;
    }
}

.skin-blue .sidebar-menu > li:hover a.nav-item.custom-nav-item,
.skin-blue .sidebar-menu > li.active a.nav-item.custom-nav-item,
.skin-blue .sidebar-menu > li a.nav-item.custom-nav-item.hover,
.skin-blue .sidebar-menu > li:hover > app-nav-menu-item > a,
.skin-blue .sidebar-menu > li.active > app-nav-menu-item > a {
    color: @navHoverBackgroundColor;
    background: @navHoverColor;
    border-left-color: #3c8dbc;
}

.custom-nav-dropdown {
    position: fixed;
    width: 280px;
    background-color: @navBackgroundColor;
    border: none;
    margin: 0px;
    padding: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    a.custom-nav-item {
        text-decoration: none;
        color: #fff;
        &:hover, &.active {
            color: @navHoverBackgroundColor;
            background: @navHoverColor;
            border-left-color: #3c8dbc;
        };
        white-space: normal;
        word-break: break-word;
        overflow: visible;
        display: block;
    }
}

.skin-blue .sidebar-menu > li > .treeview-menu,
.skin-blue .sidebar-menu > li > .treeview-menu {
    margin: 0 1px;
    background: @navDarkBackgroundColor;
}

.skin-blue .sidebar-menu > li.header {
    color: @navDarkColor;
    background: @navDarkBackgroundColor;
}

.skin-blue .main-header .logo:hover {
    background-color: @navBackgroundColor;
}

.skin-blue .main-header .logo {
    background-color: @navBackgroundColor;
    color: #fff;
    border-bottom: 0 solid transparent;
}

.skin-blue .main-header .navbar {
    background-color: @navBackgroundColor;
}
